import { Ctrl } from ":mods";
import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { Signup, Login, PasswordReset } from "../views";
import { AuthRoutesViewProps } from "../models";

export function AuthRoutes(props: AuthRoutesViewProps) {
  return (
    <>
      <Route path={ROUTES.auth.login} element={<Login {...props} />} />
      <Route path={ROUTES.auth.signup} element={<Signup {...props} />} />
      <Route path={ROUTES.auth.password_reset} element={<PasswordReset {...props} />} />
    </>
  );
}
